//App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import Header from './Components/Header.jsx';
import LoginPage from './Components/LoginPage';
import OperatorForm from './Components/OperatorForm';

import { Provider } from 'react-redux';
import store from './Redux/store';

function App() {
  return (
    <Router>
      <React.Fragment>
        <main>
          <Provider store={store}>
            <Routes>
              <Route path='/login' element={<LoginPage />} />
              <Route path='/operator-form' element={<OperatorForm />} />
              <Route path='/*' element={<HeaderWrapper />} />
            </Routes>
          </Provider>
        </main>
      </React.Fragment>
    </Router>
  );
}


function HeaderWrapper() {
  return (
    <React.Fragment>
      <div className="sticky-top mt-0">
        <Header />
      </div>
      <Container className='my-5'>
        <Row className='mb-5'>
          <Col sm='5'>
            <Link className='btn btn-primary' to='/login'>Go to Login Form</Link>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default App;
