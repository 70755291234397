//LoginPage.jsx
import React from 'react';
import Header from './Header.jsx';
import LoginForm from './LoginForm';

function LoginPage() {
   return (
      <div>
         <div className="sticky-top">
            <Header />
         </div>
         <main className='my-5'>
            <LoginForm />
         </main>
      </div>
   );
}

export default LoginPage;