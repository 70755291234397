// reducers.js
const SET_USER = 'SET_USER';

const userReducer = (state = null, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...action.payload,
        imageUrl: action.payload.picture || state?.picture || null
      };
    default:
      return state;
  }
};

export { userReducer, SET_USER };
