import { useEffect, useCallback } from 'react';
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../Redux/actions';

function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoogleLogin = useCallback((response) => {
    const userObject = jwt_decode(response.credential);

    // Dispatch the setUser action
    dispatch(setUser(userObject));

    // Redirect to the OperatorForm page
    navigate('/operator-form', { state: { userData: userObject } });
  }, [dispatch, navigate]);

  useEffect(() => {
      /* global google */
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogleLogin
      });

      google.accounts.id.renderButton(
        document.getElementById('google-login-button'),
        { theme: 'outline', size: 'large' }
      );
  }, [handleGoogleLogin]);

  return (
    <div>
      <div id="google-login-button" />
    </div>
  );
}

export default LoginForm;
