// actions.js
import { SET_USER } from './reducers';

export const setUser = ({ name = '', email = '', picture = '' } = {}) => ({
  type: SET_USER,
  payload: {
    name,
    email,
    picture,
  },
});

