import React, { useState } from 'react';
import { Container, Form, Button, Card, Image } from 'react-bootstrap';
import Header from './Header.jsx';
import { useSelector } from 'react-redux';

const OperatorForm = () => {
  const [selectedOperator, setSelectedOperator] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null); // Added state to store the uploaded image
  const user = useSelector(state => state.user);

  const handleOperatorSelect = (e) => {
    setSelectedOperator(e.target.value);
  }
  
  const handleImageError = (e) => {
    e.target.src = `${user.picture}?${Date.now()}`;
  };  

  const handleImageUpload = (e) => { // Added a function to handle image upload
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUploadedImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append('email', user.email);
    const entries = Array.from(formData.entries());
    console.log(Object.fromEntries(entries));
  }

  return (
    <>
      <Header />
      <Container className="my-4">
        {user && (
          <Card className="mb-4">
            <Card.Body>
            <div className="d-flex align-items-center">
              <Image
                src={user.picture}
                roundedCircle
                className="mr-3"
                onError={handleImageError}
              />
                <div>
                  <Card.Title>{user.name}</Card.Title>
                  <Card.Text>{user.email}</Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        )}
        <Card>
          <Card.Body>
            <Card.Title>Operator Form</Card.Title>
            <Form onSubmit={handleSubmit}>
              {user && (
                <input type="hidden" name="email" value={user.email} />
              )}
              <Form.Group controlId="operatorSelect">
                <Form.Label>Operator { user.name }</Form.Label>
                <Form.Control as="select" value={selectedOperator} onChange={handleOperatorSelect}>
                  {!user && <option>Please log in to select an operator</option>}
                  {user && (
                    <>
                      <option value="">Select an operator</option>
                      <option value="Operator 1">Operator 1</option>
                      <option value="Operator 2">Operator 2</option>
                      <option value="Operator 3">Operator 3</option>
                    </>
                  )}
                </Form.Control>
              </Form.Group>
              {/* Added input field for image upload */}
              {selectedOperator && (
                <Form.Group controlId="imageUpload">
                  <Form.Label>Upload Image</Form.Label>
                  <Form.Control type="file" name="image" accept="image/*" onChange={handleImageUpload} />
                </Form.Group>
              )}
              {/* Added a component to display the uploaded image */}
              {uploadedImage && (
                <div className="mb-3">
                  <Image src={uploadedImage} thumbnail />
                </div>
              )}
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default OperatorForm;
